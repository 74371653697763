import './App.css';
import { Amplify } from 'aws-amplify';
import {
  Authenticator, Button, Flex, Link, Image,
} from '@aws-amplify/ui-react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Route, Routes, Link as ReactRouterLink } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import UserList from './components/user/userList';

const awsmobile = {
  aws_project_region: process.env.REACT_APP_REGION,
  aws_cognito_region: process.env.REACT_APP_REGION,
  aws_user_pools_id: process.env.REACT_APP_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  aws_cognito_identity_pool_id: process.env.REACT_APP_IDENTITY_POOL_ID,
  oauth: {},
  aws_cognito_username_attributes: [
    'EMAIL',
  ],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: [],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: [],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [
      'REQUIRES_LOWERCASE',
      'REQUIRES_UPPERCASE',
      'REQUIRES_NUMBERS',
      'REQUIRES_SYMBOLS',
    ],
  },
  aws_cognito_verification_mechanisms: [
    'EMAIL',
  ],
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APP_SYNC_GRAPHQL_URL,
  aws_appsync_region: process.env.REACT_APP_REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: 'bepl-bucket-dev',
      region: process.env.REACT_APP_REGION,
    },
  },
};

Amplify.configure(awsmobile);

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Authenticator loginMechanisms={['email']} hideSignUp>
        {({ signOut }) => (
          <div className="App">
            <header style={{ backgroundColor: '#282828' }}>
              <Flex direction="row" justifyContent="right">
                <Button onClick={signOut}>Sign Out</Button>
              </Flex>
            </header>
            <Flex direction="row" justifyContent="center">
              <nav>
                <Flex direction="column" justifyContent="center">
                  <Flex direction="row" justifyContent="center">
                    <Link as={ReactRouterLink} to="/">
                      <Image src="/bepl_logo_admin_bi.png" alt="brand image" />
                    </Link>
                  </Flex>
                  <Link as={ReactRouterLink} to="/company">
                    <Button variation="link">
                      회사
                    </Button>
                  </Link>
                  <Link as={ReactRouterLink} to="/user">
                    <Button variation="link">
                      일반 사용자
                    </Button>
                  </Link>
                </Flex>
              </nav>
              <main>
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/user" element={<UserList />} />
                </Routes>
              </main>
            </Flex>
          </div>
        )}
      </Authenticator>
    </QueryClientProvider>
  );
}

export default App;
