import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  Divider, Flex, Table, TableBody, TableCell, TableHead, TableRow,
} from '@aws-amplify/ui-react';
import { API, graphqlOperation } from 'aws-amplify';
import { useQuery } from 'react-query';
import { User, UsersOnAdminQuery } from '../../API';
import { usersOnAdmin } from '../../graphql/queries';

function UserList() {
  const getUsers = async () => {
    const result = await API.graphql(
      graphqlOperation(usersOnAdmin),
    ) as GraphQLResult<UsersOnAdminQuery>;

    return result.data?.usersOnAdmin ?? [];
  };

  // Queries
  const query = useQuery<User[], Error>('companies', getUsers);

  return (
    <Flex className="userList" direction="column">
      <h1>일반 사용자</h1>
      <Divider orientation="horizontal" />
      <Table
        highlightOnHover={false}
        size={undefined}
        variation={undefined}
      >
        <TableHead>
          <TableRow>
            <TableCell as="th">username</TableCell>
            <TableCell as="th">이름</TableCell>
            <TableCell as="th">메인 포지션 / 서브 포지션</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            query.data?.map((user) => (
              <TableRow key={user.userId}>
                <TableCell>{user.userName}</TableCell>
                <TableCell>{user.nickname}</TableCell>
                <TableCell>
                  {user.mainPosition}
                  {' '}
                  /
                  {' '}
                  {user.mainPosition}
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    </Flex>
  );
}

export default UserList;
