/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const usersOnAdmin = /* GraphQL */ `
  query UsersOnAdmin {
    usersOnAdmin {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const positionsOnApp = /* GraphQL */ `
  query PositionsOnApp {
    positionsOnApp {
      type
      title
    }
  }
`;
export const feedOnApp = /* GraphQL */ `
  query FeedOnApp {
    feedOnApp {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const myInfoOnApp = /* GraphQL */ `
  query MyInfoOnApp {
    myInfoOnApp {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const userOnApp = /* GraphQL */ `
  query UserOnApp($id: ID!) {
    userOnApp(id: $id) {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const userByUsernameOnApp = /* GraphQL */ `
  query UserByUsernameOnApp($username: String!) {
    userByUsernameOnApp(username: $username) {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const companyAccountOnApp = /* GraphQL */ `
  query CompanyAccountOnApp($username: String!) {
    companyAccountOnApp(username: $username) {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const careersOnApp = /* GraphQL */ `
  query CareersOnApp($userId: ID!) {
    careersOnApp(userId: $userId) {
      id
      companyName
      companyAccountUsername
      mainPosition
      startDate
      endDate
      colleagueCount
    }
  }
`;
export const followingsOnApp = /* GraphQL */ `
  query FollowingsOnApp($userId: ID!) {
    followingsOnApp(userId: $userId) {
      userId
      nickname
    }
  }
`;
export const followersOnApp = /* GraphQL */ `
  query FollowersOnApp($userId: ID!) {
    followersOnApp(userId: $userId) {
      userId
      nickname
    }
  }
`;
export const recruitmentsOnApp = /* GraphQL */ `
  query RecruitmentsOnApp {
    recruitmentsOnApp {
      id
      title
    }
  }
`;
export const refereesOnApp = /* GraphQL */ `
  query RefereesOnApp($userId: ID!) {
    refereesOnApp(userId: $userId) {
      userId
      userName
      userType
      nickname
      mainPosition
      description
      companyId
      followingCount
      followerCount
      isFollowing
      careers {
        id
        companyName
        companyAccountUsername
        mainPosition
        startDate
        endDate
        colleagueCount
      }
      version
      companyName
      businessRegistrationNumber
      profileImagePath
      portfolioPath
    }
  }
`;
export const myReferenceCheckUsersOnApp = /* GraphQL */ `
  query MyReferenceCheckUsersOnApp {
    myReferenceCheckUsersOnApp {
      type
      requestUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      targetUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      refereeUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
    }
  }
`;
export const referenceCheckListOnApp = /* GraphQL */ `
  query ReferenceCheckListOnApp($targetUserId: ID!) {
    referenceCheckListOnApp(targetUserId: $targetUserId) {
      id
      requestUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      targetUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      refereeUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      status
      requestedAt
      acceptedAt
      referenceCheckItem {
        workCompetence
        workingAttitude
        communicationSkill
        personality
        laborIssue
        resignIssue
        moralityIssue
        workTogetherAgain
      }
    }
  }
`;
export const referenceCheckOnApp = /* GraphQL */ `
  query ReferenceCheckOnApp($targetUserId: ID!, $referenceCheckId: ID!) {
    referenceCheckOnApp(
      targetUserId: $targetUserId
      referenceCheckId: $referenceCheckId
    ) {
      id
      requestUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      targetUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      refereeUser {
        userId
        userName
        userType
        nickname
        mainPosition
        description
        companyId
        followingCount
        followerCount
        isFollowing
        version
        companyName
        businessRegistrationNumber
        profileImagePath
        portfolioPath
      }
      status
      requestedAt
      acceptedAt
      referenceCheckItem {
        workCompetence
        workingAttitude
        communicationSkill
        personality
        laborIssue
        resignIssue
        moralityIssue
        workTogetherAgain
      }
    }
  }
`;
export const notificationsOnApp = /* GraphQL */ `
  query NotificationsOnApp {
    notificationsOnApp {
      title
      description
      contents
      createdAt
    }
  }
`;
